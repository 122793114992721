<template>
  <header class="text-gray-100 body-font mt-3 mb-3">
    <div
      class="
        container
        mx-auto
        flex flex-wrap
        p-0
        flex-col
        items-center
      "
    >
      <a
        class="
          flex
          title-font
          font-medium
          items-center
          text-gray-900
          mb-4
          md:mb-0
        "
        href=""
      >
        <img src="../assets/cloudcatalog.svg" alt="" class="w-15" />
        <span class="ml-3 text-3xl">Cloud Catalog</span>
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {},
};
</script>